import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Field, change } from "redux-form"
import DurationNumberField from "./DurationNumberField"
import { getFrenchDuration } from "../utils"

const ReferenceDuration = props => {
    const {
        periodType,
        line,
        readOnly,
        additionalText,
        values,
        editAllTPT0daysPerYear,
        editAllTPF0daysPerYear,
        index,
        dispatch,
        editAllPIndDaysPerYear,
        victimId,
        indirectVictim
    } = props;

    const bulkEditAllValues = (newValue, line, periodType) => {
        const itemType = line.split('[')[0]
        const shouldEditAllTPT0daysPerYear = itemType === 'TPT0' && editAllTPT0daysPerYear
        const shouldEditAllTPF0daysPerYear = itemType === 'TPF0' && editAllTPF0daysPerYear
        const shouldEditAllPInd0daysPerYear = itemType === 'PInd0' && editAllPIndDaysPerYear

        if (shouldEditAllTPT0daysPerYear) {
            for (let i = 0; i < values[itemType].length; i++) {
                if (i !== index) {
                    // Dispatch the change action for every index except the one being skipped
                    dispatch(change("caseDataForm", `${itemType}[${i}].daysPerYear`, newValue));
                }
            }
        }
        else if (shouldEditAllTPF0daysPerYear) {
            for (let i = 0; i < values[itemType].length; i++) {
                if (i !== index) {
                    // Dispatch the change action for every index except the one being skipped
                    dispatch(change("caseDataForm", `${itemType}[${i}].daysPerYear`, newValue));
                }
            }
        }
        else if (shouldEditAllPInd0daysPerYear) {
            for (let i = 0; i < indirectVictim.length; i++) {
                if (i !== index) {
                    // Dispatch the change action for every index except the one being skipped
                    dispatch(change("caseDataForm", `indirectVictims[${victimId}].caseData.PInd0[${i}].daysPerYear`, newValue))
                }
            }
        }
    }
    return (
        <Fragment>
            <div className="input-group-prepend pl-1">
                <span className="input-group-text">&nbsp;sur la base de</span>
            </div>
            <Field
                name={`${line}.daysPerYear`}
                component={DurationNumberField}
                readOnly={readOnly}
                disabled={readOnly}
                onChange={(e, newValue) => bulkEditAllValues(newValue, line, periodType)}
            />
            <div className="input-group-append">
                <span className="input-group-text">jours par an{additionalText ? additionalText
                    : ""}</span>
            </div>
        </Fragment>
    );
};

ReferenceDuration.propTypes = {
    periodType: PropTypes.string,
    line: PropTypes.string,
    readOnly: PropTypes.bool,
    additionalText: PropTypes.string,
    values: PropTypes.object,
    editAllTPT0periodRate: PropTypes.bool,
    index: PropTypes.number,
    dispatch: PropTypes.func
};

export default ReferenceDuration;