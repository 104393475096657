import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { change, reset, FormSection, formValueSelector } from "redux-form";
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { getIndirectVictimFullName, getIndirectVictimLink } from "../../utils";
import IndirectVictim from '../case_data_form/IndirectVictim';
import DirectVictimEntries from '../case_data_form/DirectVictimEntries';
import IndirectVictimBtn from './IndirectVictimBtn';
import { indirectVictimModel } from "../../physical_injury/dataModels";
import { BoxChecked, BoxPInd0Checked } from "../case_data_form/utils";
const a11yProps = (index) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  tabLabel: {
    textTransform: 'none',
  }
}));



const TabLabel = (props) => {
  const { victim, i } = props;
  const classes = useStyles();

  return (
    <div>
      <Typography variant="button" className={classes.tabLabel}>{getIndirectVictimFullName(victim.victim, i, 30)}</Typography>
      <Typography className={classes.tabLabel}>({getIndirectVictimLink(victim.victim)})</Typography>
    </div>
  );
}

const VictimTabs = (props) => {
  const { caseValues, directVictim, indirectVictims, currentVictim, dispatch, nbIndirectVictims, formIndirectVictims, setIncomeFormOpen, setMethodChoiceOpen } = props
  const classes = useStyles();

  const [addVictimFormOpen, setAddVictimFormOpen] = React.useState(false);
  const handleChange = (event, newValue) => {
    dispatch(change("caseDataForm", "currentVictim", newValue));
  };
  const currentTab = Math.min(currentVictim, indirectVictims.length);

  const [editAllPIndperiodRate, setEditAllPIndperiodRate] = React.useState(false)
  const [editAllPIndDaysPerYear, setEditAllPIndDaysPerYear] = React.useState(false)

  const [editAllDFT0, setEditAllDFT0] = React.useState(false)
  const [editAllTPT0periodRate, setEditAllTPT0periodRate] = React.useState(false)
  const [editAllTPT0daysPerYear, setEditAllTPT0daysPerYear] = React.useState(false)
  const [editAllTPF0periodRate, setEditAllTPF0periodRate] = React.useState(false)
  const [editAllTPF0daysPerYear, setEditAllTPF0daysPerYear] = React.useState(false)

  React.useEffect(() => {
    const DFTperiodRate = BoxChecked('DFT0', 'periodRate', caseValues);
    const TPT0periodRate = BoxChecked('TPT0', 'periodRate', caseValues);
    const TPT0daysPerYear = BoxChecked('TPT0', 'daysPerYear', caseValues);
    const TPF0periodRate = BoxChecked('TPF0', 'periodRate', caseValues);
    const TPF0daysPerYear = BoxChecked('TPF0', 'daysPerYear', caseValues);
    setEditAllDFT0(DFTperiodRate)
    setEditAllTPT0periodRate(TPT0periodRate)
    setEditAllTPT0daysPerYear(TPT0daysPerYear)
    setEditAllTPF0periodRate(TPF0periodRate)
    setEditAllTPF0daysPerYear(TPF0daysPerYear)

    const periodRate = BoxPInd0Checked('periodRate', indirectVictims?.[currentVictim - 1]?.caseData?.PInd0) ?? false;
    const daysPerYear = BoxPInd0Checked('daysPerYear', indirectVictims?.[currentVictim - 1]?.caseData?.PInd0) ?? false;
    setEditAllPIndperiodRate(periodRate)
    setEditAllPIndDaysPerYear(daysPerYear)
  }, [currentTab])

  const handleSubmitIndirectVictim = formValues => {
    if (!formValues.linkType) formValues.linkType = "spouse";
    let newIndirectVictim = {
      victim: formValues,
      caseData: {},
      caseDataForm: indirectVictimModel
    }
    let newIndirectVictims = formIndirectVictims;
    newIndirectVictims.push(newIndirectVictim)

    dispatch(change("caseDataForm", "indirectVictims", newIndirectVictims));
    dispatch(change("caseDataForm", "currentVictim", nbIndirectVictims));
    dispatch(reset('indirectVictimForm'));
    setAddVictimFormOpen(false);
  }

  return (
    <div className={classes.root} style={{ backgroundColor: 'white', }}>
      <div className="sticky-top sticky-offset-tabs d-flex">
        <div className="w-100">
          <AppBar position="sticky" color="default">
            <Tabs
              value={currentTab}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              style={{ minHeight: "56px" }}
            >
              <Tab label="Victime directe" {...a11yProps(0)} className={classes.tabLabel} />
              {indirectVictims.map((victim, i) => <Tab key={i} label={<TabLabel victim={victim} i={i} />} {...a11yProps(i + 1)} />)}
            </Tabs>
          </AppBar>
        </div>
        <IndirectVictimBtn onSubmit={handleSubmitIndirectVictim} handleAddVictimForm={setAddVictimFormOpen} open={addVictimFormOpen} />
      </div>
      {currentTab === 0 && <div className='mt-2'>
        <DirectVictimEntries
          victim={directVictim}
          editAllDFT0={editAllDFT0}
          setEditAllDFT0={setEditAllDFT0}
          editAllTPT0periodRate={editAllTPT0periodRate}
          setEditAllTPT0periodRate={setEditAllTPT0periodRate}
          editAllTPT0daysPerYear={editAllTPT0daysPerYear}
          setEditAllTPT0daysPerYear={setEditAllTPT0daysPerYear}
          editAllTPF0periodRate={editAllTPF0periodRate}
          setEditAllTPF0periodRate={setEditAllTPF0periodRate}
          editAllTPF0daysPerYear={editAllTPF0daysPerYear}
          setEditAllTPF0daysPerYear={setEditAllTPF0daysPerYear} /></div>}
      {currentTab !== 0 && indirectVictims[currentVictim - 1] && indirectVictims[currentVictim - 1].victim &&
        <div className="sticky-top sticky-offset-indirect">
          <FormSection
            name={`indirectVictims[${currentVictim - 1}]`}
            component={IndirectVictim}
            indirectVictim={indirectVictims[currentVictim - 1]}
            victimId={currentVictim - 1}
            directVictim={directVictim}
            setIncomeFormOpen={setIncomeFormOpen}
            setMethodChoiceOpen={setMethodChoiceOpen}
            editAllPIndperiodRate={editAllPIndperiodRate}
            setEditAllPIndperiodRate={setEditAllPIndperiodRate}
            editAllPIndDaysPerYear={editAllPIndDaysPerYear}
            setEditAllPIndDaysPerYear={setEditAllPIndDaysPerYear}
          />
        </div>
      }
    </div>
  );
}

VictimTabs.propTypes = {
  indirectVictims: PropTypes.array.isRequired,
  directVictim: PropTypes.object.isRequired,
  currentVictim: PropTypes.number,
  dispatch: PropTypes.func,
  setIncomeFormOpen: PropTypes.func,
  setMethodChoiceOpen: PropTypes.func,
  nbIndirectVictims: PropTypes.number,
  formIndirectVictims: PropTypes.array,
};


const dataSelector = formValueSelector("caseDataForm");

const mapStateToProps = state => {
  let nbIndirectVictims = 0;
  let formIndirectVictims = [];

  if (dataSelector(state, "indirectVictims"))
    formIndirectVictims = dataSelector(state, "indirectVictims");
  if (formIndirectVictims)
    nbIndirectVictims = formIndirectVictims.length + 1;

  return { nbIndirectVictims, formIndirectVictims };
};


export default connect(mapStateToProps)(memo(VictimTabs));
