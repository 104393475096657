import React from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

export const ConfirmBulkEditModal = props => {
    const { open, field_label, first_line_field_value, onClose, handleConfirm } = props;
    return (
        <React.Fragment>
            {open ? <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    style: {
                        boxShadow: 'none', // Remove the shadow around the dialog box
                    }
                }}
                BackdropProps={{
                    style: {
                      backgroundColor: 'rgba(0, 0, 0, 0.1)', // Adjust the background color with transparency
                      opacity: 1,
                      transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1), background-color 225ms cubic-bezier(0.4, 0, 0.2, 1)' 
                    }
                  }}
            >
                <DialogTitle id="alert-dialog-title">Lier les périodes entre elles</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {`Le ${field_label} de la première ligne (${first_line_field_value}) sera appliqué à toutes les lignes. Vous pourrez modifier cette valeur pour toutes les périodes en la changeant sur une seule d'entre elles, puisqu'elles sont désormais toutes liées.`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary" size="small" title="Annuler">
                        Annuler
                    </Button>
                    <Button onClick={() => handleConfirm()} variant="contained" color="secondary" size="small" title="Confirmer" autoFocus>
                        Confirmer
                    </Button>
                </DialogActions>
            </Dialog> : null}
        </React.Fragment >
    );
};