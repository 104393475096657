import {makeStyles} from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import PropTypes from "prop-types";
import React from "react";
import {Divider, Input} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment'


const useStyles = makeStyles((theme) => ({
    outlinedContainer: {
        position: 'relative',
        display: 'flex',
        minWidth: 300,
        alignItems: 'center',
        border: '1px solid',
        borderRadius: theme.shape.borderRadius,
        transition: 'border-color 0.2s',
        // '&:hover': {
        //     borderColor: '#8B2D75',
        // },
        // '&:focus-within': {
        //     borderColor: '#8B2D75',
        // },
    },
    label: {
        position: 'absolute',
        left: 12,
        top: -10,
        background: 'white',
        padding: '0 4px',
        fontSize: 12,
        color: '#3F70A2',

    },
    select: {
        flex: 1,
        '& .MuiSelect-select': {
            padding: theme.spacing(1, 2),
        },
    },
    input: {
        '& .MuiInput-input':{
            padding: theme.spacing(1, 2),
        },
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        flex: 2,
    },
    unit: {
        marginRight: '12px',
        fontWeight: 600,
        fontSize: 12
    }
}));


export function InterestRateSelection(props) {
    const classes = useStyles();

    // props.entries
    // {key:string, name:string, hasInput:boolean}[]

    const handleSelectChange = (event) => {
        props.setSelected(event.target.value);
    };

    const handleInputChange = (event) => {
        try {
            const parsed = parseFloat(event.target.value)
            if (isNaN(parsed)){
                props.setInputValue(event.target.value);
                return
            }
            // do not allow negative values
            if (parsed <0){
                props.setInputValue("0");
                return
            }
            props.setInputValue(event.target.value);
        } catch (error) {
            props.setInputValue(event.target.value);
        }
    };

    const selectedEntry = props.entries
        .find(entry => props.selected === entry.key)

    const hasInput = selectedEntry ? selectedEntry.hasInput : false;
    const helperText = selectedEntry ? selectedEntry.helperText : '';


    return (<>

        <div className={classes.outlinedContainer}>
            {props.label ? <span className={classes.label}>
                {props.label}{props.required ? <span style={{color: 'red'}}>*</span> : null}
            </span> : null}
            <Select
                value={props.selected}
                onChange={handleSelectChange}
                className={classes.select}
                disableUnderline
                displayEmpty
                size="small"
            >
                <MenuItem value="">
                    <em>{props.emptyItemTitle || "None"}</em>
                </MenuItem>
                {props.entries.map((entry) => (
                    <MenuItem key={entry.key} value={entry.key}>
                        {entry.name}
                    </MenuItem>
                ))}
            </Select>
            {hasInput && (
                <><Divider orientation="vertical" flexItem/>
                    <>
                        <TextField
                            type="number"
                            value={props.inputValue}
                            onChange={handleInputChange}
                            InputProps={{ 
                                min: 0,
                                endAdornment: <InputAdornment position="end">{props.unit}</InputAdornment>,
                            }}
                            className={classes.input}
                        />
                    </>
                </>
            )}

        </div>
        <FormHelperText>{helperText ? helperText : <>&nbsp;</>}</FormHelperText>
        </>
    )
}

InterestRateSelection.propTypes = {
    entries: PropTypes.array.isRequired,
    unit: PropTypes.string,
    emptyItemTitle: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    selected: PropTypes.string,
    setSelected: PropTypes.func.isRequired,
    inputValue: PropTypes.string,
    setInputValue: PropTypes.func.isRequired,
};
