import React from 'react';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    customButton: {
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        color: 'black',
        '&:hover': {
            backgroundColor: '#ccc',
        },
        '&:focus': {
            border: '1px solid #ccc',
            outline: 'none',
        },
        '&:active': {
            border: '1px solid #ccc',
            outline: 'none',
        },
        transition: 'background-color 0.3s ease',
        maxHeight: '20px',
        maxWidth: '20px',
        minWidth: '20px',
        display: 'flex',
        zIndex: 1000,
    },
}));


const handleBlur = (buttonRef) => {
    if (buttonRef.current) {
        buttonRef.current.blur(); // Remove focus after click
    }
};

export function MoveUp({ moveFieldUp, disabled = false }) {
    const classes = useStyles();
    const buttonRef = React.useRef(null);
    return (
        <Button
            type="button"
            ref={buttonRef}
            className={classes.customButton}
            style={{ marginTop: '4px', marginBottom: '8px' }}
            title="Déplacer la ligne vers le haut"
            disabled={disabled}
            onClick={() => {
                moveFieldUp()
                handleBlur(buttonRef)
            }}
        >
            <KeyboardArrowUpIcon
                style={{
                    fontSize: 'large',
                }}
            />
        </Button>
    );
}

export function MoveDown({ moveFieldDown, disabled = false }) {
    const classes = useStyles();
    const buttonRef = React.useRef(null);
    return (
        <Button
            type="button"
            ref={buttonRef}
            className={classes.customButton}
            title="Déplacer la ligne vers le bas"
            disabled={disabled}
            onClick={() => {
                moveFieldDown()
                handleBlur(buttonRef)
            }}
        >
            <KeyboardArrowUpIcon
                style={{
                    fontSize: 'large',
                    transform: 'rotate(180deg)',
                }} />
        </Button>
    );
}