var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { change, Field, reduxForm, FormSection } from "redux-form";
import { AppBar, Backdrop, Box, Fade, FormControlLabel, FormHelperText, Grid, InputAdornment, Modal, Paper, Switch, Tab, Tabs, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, } from "@material-ui/core";
import { withStyles, makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { TrendingUp, Info } from '@material-ui/icons/';
import { InterestRateSelection } from "../fields/InterestRateSelection";
import { helpEntries } from "../../cases/physical_injury/constants";
import { formatDateField, interval } from "../utils";
var palette = {
    primary: {
        light: '#FAF0F8',
        main: '#9E3386',
    },
    secondary: {
        light: '#D3E0EE',
        main: '#213B55',
    },
    error: {
        light: '#F0F5F9',
        main: '#F54952',
    },
    success: {
        main: '#22A8AA',
    },
    info: {
        light: '#ECF6FE',
        main: '#0D7AD3',
    },
    warning: {
        main: '#F5CA66',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    //   contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    //   tonalOffset: 0.2,
};
var localTheme = createMuiTheme({
    palette: palette,
    typography: {
        fontFamily: 'Roboto',
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        allVariants: {
            fontSize: 14,
            color: '#213B55',
        },
    },
    overrides: {
        MuiTableCell: {
            root: {
                borderBottom: 'none', // Remove bottom border
                borderRight: '1px solid #A7C2DD', // Add border between columns on the right side
            },
            head: {
                '&:first-child': {
                    borderLeft: '1px solid #A7C2DD', // Add left border for the first column in table header
                },
            },
            body: {
                '&:first-child': {
                    borderLeft: '1px solid #A7C2DD', // Add left border for the first column in table body
                },
            },
        },
        MuiFormHelperText: {
            root: {
                fontSize: 12,
                color: '#3F70A2',
            },
        },
        MuiInputLabel: {
            shrink: {
                color: '#3F70A2',
                transform: 'translate(14px, -6px) scale(0.8571)!important',
            },
        },
        MuiFormLabel: {
            asterisk: {
                color: palette.error.main, // Set the desired color for the asterisk
            },
        },
        MuiOutlinedInput: {
            root: {
                '& fieldset': {
                    borderColor: '#3F70A2', // Set the desired border color
                },
            },
            notchedOutline: {
                '& legend': {
                    fontSize: '0.75rem',
                },
            },
        },
        MuiTab: {
            textColorPrimary: {
                color: '#213B55', // Define the text color for unselected tabs here
            },
            root: {
                color: '#213B55',
                backgroundColor: '#F0F5F9',
                "&$selected": {
                    color: '#9E3386',
                    borderBottom: "2px solid #9E3386",
                    backgroundColor: '#FAF0F8',
                },
                minHeight: '48px',
                maxHeight: '48px',
            },
            wrapper: {
                minHeight: '48px',
                maxHeight: '48px',
            },
        },
        MuiTabs: {
            indicator: {
                display: 'none',
            },
        },
    },
});
var useStyles = makeStyles(function (localTheme) { return ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: localTheme.palette.background.paper,
        border: 'none',
        boxShadow: localTheme.shadows[5],
        padding: localTheme.spacing(2, 4, 3),
        minHeight: '90vh',
        maxHeight: '90vh',
        minWidth: '80vw',
        maxWidth: '80vw',
    },
    appBar: {
        border: 0,
        borderBottom: "1px solid ".concat(localTheme.palette.divider),
        minHeight: '48px',
        maxHeight: '48px',
    },
    tabLabel: {
        // textColorPrimary: localTheme.palette.secondary.main,
        textTransform: 'none',
        maxWidth: '200px',
        minWidth: '180px',
        fontWeight: 'normal',
        display: 'flex',
        alignItems: 'center',
    },
    closeButton: {
        marginLeft: 'auto',
    },
    addButtonTab: {
        maxWidth: '100px!important', // Set the specific width for the "Ajouter" tab
        minWidth: '100px!important',
        border: '1px solid #E0E0E0',
    },
    input: {
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    },
    table: {
        minWidth: 650,
        width: "100%"
    },
    resultsTable: {
        maxWidth: 400,
        minWidth: 380,
    },
    root: {
        width: '100%',
        marginTop: "20px",
    },
    container: {
        maxHeight: 430,
    },
    ResultsTableCell: {
        textAlign: "left"
    },
    boldTableRow: {
        '& > *': {
            fontWeight: 900,
        },
    },
    resultsTableTotalRow: {
        '& > *': {
            fontSize: 16,
        },
    },
    footerResultsTableCell: {
        fontSize: "16px!important",
    },
    commentField: {
        flex: 1,
    },
    centerContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    tableContainerGrid: {
        marginTop: 20,
        maxWidth: 400,
    },
}); });
var CaseInterestsButton = function (props) {
    var interests = props.interests;
    var dispatch = useDispatch();
    var classes = useStyles();
    var _a = React.useState(false), open = _a[0], setOpen = _a[1];
    var handleOpen = function () {
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    var handleAddInterests = function () {
        var newInterests = { title: "", baseAmount: 0 };
        var newInterestsList = interests.interestsList;
        newInterestsList.push(newInterests);
        dispatch(change("interestsForm", "interestsList", newInterestsList));
        dispatch(change("interestsForm", "activeTab", newInterestsList.length - 1));
    };
    var currentInterestsTab = interests.activeTab || 0;
    var handleChangeTab = function (event, newValue) {
        dispatch(change("interestsForm", "activeTab", newValue));
    };
    var getInterestsTabLabel = function (title, tabId) {
        var tabLabel = "Calcul d'intérêts n° " + (tabId + 1);
        if (title) {
            tabLabel = title;
            if (tabLabel.length > 43)
                tabLabel = tabLabel.slice(0, 43).trim() + "...";
        }
        return tabLabel;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("button", { type: "button", className: "btn btn-secondary btn-sm", style: { border: '1px solid #40A5F4', color: '#40A5F4', fontWeight: 800 }, onClick: handleOpen },
            React.createElement(TrendingUp, null),
            "\u00A0 Calcul d'int\u00E9r\u00EAts"),
        React.createElement(ThemeProvider, { theme: localTheme },
            React.createElement(Modal, { "aria-labelledby": "transition-modal-title", "aria-describedby": "transition-modal-description", className: classes.modal, open: open, onClose: handleClose, closeAfterTransition: true, BackdropComponent: Backdrop, BackdropProps: {
                    timeout: 500,
                } },
                React.createElement(Fade, { in: open },
                    React.createElement("fieldset", { className: classes.paper },
                        React.createElement("h2", { id: "transition-modal-title" }, "Calculs d'int\u00E9r\u00EAts"),
                        React.createElement("div", { className: "sticky-top sticky-offset-tabs d-flex" },
                            React.createElement("div", { style: { width: "100%" } },
                                React.createElement(AppBar, { position: "sticky", color: "transparent", variant: "outlined", className: classes.appBar },
                                    React.createElement(Tabs, { value: currentInterestsTab, onChange: handleChangeTab, indicatorColor: "primary", textColor: "primary", variant: "scrollable", scrollButtons: "auto", "aria-label": "scrollable auto tabs example", style: { minHeight: "52px" } },
                                        interests.interestsList ?
                                            interests.interestsList.map(function (item, i) {
                                                return React.createElement(Tab, { key: i, label: getInterestsTabLabel(item.title, i), className: classes.tabLabel });
                                            }) : null,
                                        React.createElement(Tab, { label: "+ Ajouter", className: "".concat(classes.tabLabel, " ").concat(classes.addButtonTab), onClick: function () { return handleAddInterests(); } }))),
                                interests.interestsList ?
                                    interests.interestsList.map(function (item, i) {
                                        return React.createElement(InterestsTab, { interestData: item, visible: currentInterestsTab === i, index: i, key: i });
                                    })
                                    :
                                        null))))))));
};
function RenderTextField(_a) {
    var input = _a.input, label = _a.label, type = _a.type, required = _a.required, InputProps = _a.InputProps, placeholder = _a.placeholder, className = _a.className, _b = _a.meta, touched = _b.touched, error = _b.error, custom = __rest(_a, ["input", "label", "type", "required", "InputProps", "placeholder", "className", "meta"]);
    var classes = useStyles();
    return (React.createElement(TextField
    //   hintText={label}
    //   floatingLabelText={label}
    //   errorText={touched && error}
    , __assign({}, input, custom, { label: label, InputLabelProps: { shrink: true, }, InputProps: InputProps, size: "small", variant: "outlined", type: type, required: required, className: "".concat(classes.input).concat(className ? " ".concat(className) : ''), placeholder: placeholder, error: !!error, helperText: error ? error : React.createElement(React.Fragment, null, "\u00A0") })));
}
function RenderSwitchField(_a) {
    var input = _a.input, label = _a.label, helpText = _a.helpText;
    var classes = useStyles();
    var Label = (React.createElement(Box, { display: "flex", justifyContent: "space-between", alignItems: "center" },
        React.createElement(Typography, { noWrap: true }, label),
        helpText &&
            React.createElement(Tooltip, { title: helpEntries[helpText], leaveDelay: 200 },
                React.createElement(Info, { fontSize: "small", style: { marginLeft: 4 } }))));
    return (React.createElement(React.Fragment, null,
        React.createElement("div", null,
            React.createElement(FormControlLabel, { control: React.createElement(Switch, __assign({}, input, { checked: input.value ? true : false })), label: Label, labelPlacement: "start" }),
            React.createElement(FormHelperText, null, "\u00A0"))));
}
function InterestsTab(props) {
    var visible = props.visible, index = props.index, interestData = props.interestData;
    if (!visible) {
        return null;
    }
    return React.createElement("div", null,
        React.createElement(FormSection, { name: "interestsList[".concat(index, "]"), component: InterestsForm, interestData: interestData, interestId: index }));
}
var StyledTableCell = withStyles(function (localTheme) { return ({
    head: {
        backgroundColor: localTheme.palette.secondary.light,
        color: localTheme.palette.secondary.main,
    },
    body: {
        color: localTheme.palette.secondary.main,
    },
    foot: {
        color: localTheme.palette.secondary.main,
    },
}); })(TableCell);
var StyledTableRow = withStyles(function (localTheme) { return ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: localTheme.palette.error.light,
        },
    },
}); })(TableRow);
var ResultsTable = withStyles({
    root: {
        position: 'relative',
        border: 'none',
        borderCollapse: 'collapse',
        '& table': {
            width: '100%',
        },
        '& td, & th': {
            border: 'none',
        },
        '& th': {
            textAlign: 'left',
        },
        '&:first-child': {
            borderLeft: 'none!important', // Add left border for the first cell
        },
    },
})(Table);
var ResultsTableWithRoundedCorners = withStyles(function (theme) { return ({
    root: {
        border: "1px solid ".concat(localTheme.palette.secondary.light),
        borderRadius: '8px',
        overflow: 'hidden',
    },
}); })(TableContainer);
var ResultsTableCell = withStyles(function (localTheme) { return ({
    body: {
        color: localTheme.palette.secondary.main,
    },
    root: {
        '&:first-child': {
            borderLeft: 'none', // Add left border for the first cell
        },
    },
}); })(TableCell);
var ResultsTableRow = withStyles(function (localTheme) { return ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: localTheme.palette.info.light,
        },
    },
}); })(TableRow);
var renderInterestRateSelection = function (_a) {
    var input = _a.input, label = _a.label, _b = _a.meta, touched = _b.touched, error = _b.error, children = _a.children, custom = __rest(_a, ["input", "label", "meta", "children"]);
    return React.createElement(InterestRateSelection, __assign({ label: label, 
        // errorText={touched && error}
        selected: input.value.selected || '', setSelected: function (value) { return input.onChange(__assign(__assign({}, input.value), { selected: value })); }, inputValue: input.value.input || "", setInputValue: function (value) { return input.onChange(__assign(__assign({}, input.value), { input: value })); }, emptyItemTitle: "Sélectionnez le taux", entries: [
            { key: 'professional', name: 'Professionnel', hasInput: false },
            { key: 'individual', name: 'Particulier', hasInput: false },
            { key: 'custom', name: 'Saisie libre', hasInput: true, helperText: "Fixe sur la période" },
        ], unit: "%" }, custom));
};
// legalInterestRates[2] = individual
// legalInterestRates[3] = professional
var legalInterestRates = [
    ['2008-01-01', '2008-12-31', 3.99, 3.99],
    ['2009-01-01', '2009-12-31', 3.79, 3.79],
    ['2010-01-01', '2010-12-31', 0.65, 0.65],
    ['2011-01-01', '2011-12-31', 0.38, 0.38],
    ['2012-01-01', '2012-12-31', 0.71, 0.71],
    ['2013-01-01', '2013-12-31', 0.04, 0.04],
    ['2014-01-01', '2014-12-31', 0.04, 0.04],
    ['2015-01-01', '2015-06-30', 4.06, 0.93],
    ['2015-07-01', '2015-12-31', 4.29, 0.99],
    ['2016-01-01', '2016-06-30', 4.54, 1.01],
    ['2016-07-01', '2016-12-31', 4.35, 0.93],
    ['2017-01-01', '2017-06-30', 4.16, 0.9],
    ['2017-07-01', '2017-12-31', 3.94, 0.9],
    ['2018-01-01', '2018-06-30', 3.73, 0.89],
    ['2018-07-01', '2018-12-31', 3.6, 0.88],
    ['2019-01-01', '2019-06-30', 3.4, 0.86],
    ['2019-07-01', '2019-12-31', 3.26, 0.87],
    ['2020-01-01', '2020-06-30', 3.15, 0.87],
    ['2020-07-01', '2020-12-31', 3.11, 0.84],
    ['2021-01-01', '2021-06-30', 3.14, 0.79],
    ['2021-07-01', '2021-12-31', 3.12, 0.76],
    ['2022-01-01', '2022-06-30', 3.13, 0.76],
    ['2022-07-01', '2022-12-31', 3.15, 0.77],
    ['2023-01-01', '2023-06-30', 4.47, 2.06],
    ['2023-07-01', '2023-12-31', 6.82, 4.22],
    ['2024-01-01', '2024-06-30', 8.01, 5.07],
    ['2024-07-01', '2024-12-31', 8.16, 4.92],
    ['2025-01-01', '2025-06-30', 7.21, 3.71],
];
function calculateInterest(interestData, legalInterestRates) {
    var interestResults = { summary: { interestsTotal: 0, total: 0, baseAmount: interestData.baseAmount || 0 }, periods: [] };
    legalInterestRates.forEach(function (period) {
        var periodStartDate = period[0];
        var periodEndDate = period[1];
        if (interestData.startDate && interestData.endDate && interestData.baseAmount && interestData.referenceRate && interestData.referenceRate.selected) {
            var periodBaseAmount = interestData.baseAmount;
            if (interestData.startDate <= periodEndDate || interestData.endDate >= periodStartDate) {
                var start = interestData.startDate > periodStartDate ? interestData.startDate : periodStartDate;
                var end = interestData.endDate < periodEndDate ? interestData.endDate : periodEndDate;
                if (start < end) {
                    var days = interval(start, end);
                    var baseInterestRate = 0;
                    if (interestData.referenceRate.selected === "individual")
                        baseInterestRate = period[2];
                    else if (interestData.referenceRate.selected === "professional")
                        baseInterestRate = period[3];
                    else if (interestData.referenceRate.selected === "custom" && interestData.referenceRate.input && interestData.referenceRate.input > 0)
                        baseInterestRate = interestData.referenceRate.input;
                    var interestRate = baseInterestRate;
                    if (interestData.multiplyRate && interestData.multiplyBy) {
                        interestRate = Math.round(interestRate * interestData.multiplyBy * 100) / 100;
                    }
                    if (interestData.majoriseRate && interestData.majoriseBy && interestData.majoriseStartFrom && interestData.majoriseStartFrom < periodEndDate) {
                        if (interestData.majoriseStartFrom < periodStartDate) {
                            interestRate = Math.round((parseFloat(interestRate.toString()) + parseFloat(interestData.majoriseBy.toString())) * 100) / 100;
                        }
                        else {
                            var newEnd = new Date(interestData.majoriseStartFrom);
                            newEnd.setDate(newEnd.getDate() - 1);
                            days = interval(start, newEnd);
                            var partialPeriodInterestAmount = Math.round(periodBaseAmount * days * interestRate / 365) / 100;
                            interestResults.summary.interestsTotal += partialPeriodInterestAmount;
                            interestResults.summary.interestsTotal = Math.round(interestResults.summary.interestsTotal * 100) / 100;
                            interestResults.periods.push({
                                startDate: formatDateField(start),
                                endDate: formatDateField(newEnd),
                                periodBaseAmount: periodBaseAmount,
                                nbDays: days,
                                baseInterestRate: baseInterestRate,
                                interestRate: interestRate,
                                interestAmount: partialPeriodInterestAmount,
                                cumulativeInterestAmount: interestResults.summary.interestsTotal,
                                comment: Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(periodBaseAmount) + " x " + days + " j / 365 x " + Intl.NumberFormat("fr-FR", { style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(interestRate / 100)
                            });
                            start = interestData.majoriseStartFrom;
                            days = interval(start, end);
                            interestRate = Math.round((parseFloat(interestRate.toString()) + parseFloat(interestData.majoriseBy.toString())) * 100) / 100;
                        }
                    }
                    if (interestData.anatocism && interestData.anatocismStartFrom && interestData.anatocismStartFrom < periodEndDate) {
                        if (interestData.anatocismStartFrom < periodStartDate) {
                            periodBaseAmount = parseFloat(interestData.baseAmount.toString()) + parseFloat(interestResults.summary.interestsTotal.toString());
                        }
                        else {
                            var newEnd = new Date(interestData.anatocismStartFrom);
                            newEnd.setDate(newEnd.getDate() - 1);
                            days = interval(start, newEnd);
                            var partialPeriodInterestAmount = Math.round(interestData.baseAmount * days * interestRate / 365) / 100;
                            interestResults.summary.interestsTotal += partialPeriodInterestAmount;
                            interestResults.summary.interestsTotal = Math.round(interestResults.summary.interestsTotal * 100) / 100;
                            interestResults.periods.push({
                                startDate: formatDateField(start),
                                endDate: formatDateField(newEnd),
                                periodBaseAmount: periodBaseAmount,
                                nbDays: days,
                                baseInterestRate: baseInterestRate,
                                interestRate: interestRate,
                                interestAmount: partialPeriodInterestAmount,
                                cumulativeInterestAmount: interestResults.summary.interestsTotal,
                                comment: Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(periodBaseAmount) + " x " + days + " j / 365 x " + Intl.NumberFormat("fr-FR", { style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(interestRate / 100)
                            });
                            start = interestData.anatocismStartFrom;
                            days = interval(start, end);
                            periodBaseAmount = parseFloat(interestData.baseAmount.toString()) + parseFloat(interestResults.summary.interestsTotal.toString());
                            periodBaseAmount = Math.round(periodBaseAmount * 100) / 100;
                        }
                    }
                    var interestAmount = Math.round(periodBaseAmount * days * interestRate / 365) / 100;
                    interestResults.summary.interestsTotal += interestAmount;
                    interestResults.summary.interestsTotal = Math.round(interestResults.summary.interestsTotal * 100) / 100;
                    interestResults.periods.push({
                        startDate: formatDateField(start),
                        endDate: formatDateField(end),
                        nbDays: days,
                        periodBaseAmount: periodBaseAmount,
                        baseInterestRate: baseInterestRate,
                        interestRate: interestRate,
                        interestAmount: interestAmount,
                        cumulativeInterestAmount: interestResults.summary.interestsTotal,
                        comment: Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(periodBaseAmount) + " x " + days + " j / 365 x " + Intl.NumberFormat("fr-FR", { style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(interestRate / 100)
                    });
                }
            }
        }
    });
    if (interestData.baseAmount)
        interestResults.summary.total = Math.round(100 * (interestResults.summary.interestsTotal + parseFloat(interestData.baseAmount.toString()))) / 100;
    return interestResults;
}
function InterestsForm(props) {
    var _a;
    var interestId = props.interestId, interestData = props.interestData;
    var classes = useStyles();
    var dispatch = useDispatch();
    var required = function (value) { return (value ? undefined : "Champ obligatoire"); };
    var handleMajoriseChange = function (majoriseRate) {
        if (majoriseRate && interestData.startDate && (!interestData.majoriseStartFrom)) {
            var majoriseStartFrom = new Date(interestData.startDate);
            majoriseStartFrom.setMonth(majoriseStartFrom.getMonth() + 2);
            dispatch(change("interestsForm", "interestsList[".concat(interestId, "].majoriseStartFrom"), formatDateField(majoriseStartFrom)));
        }
    };
    var handleAnatocismChange = function (anatocism) {
        if (anatocism && interestData.startDate && (!interestData.anatocismStartFrom)) {
            var anatocismStartFrom = new Date(interestData.startDate);
            anatocismStartFrom.setFullYear(anatocismStartFrom.getFullYear() + 1);
            dispatch(change("interestsForm", "interestsList[".concat(interestId, "].anatocismStartFrom"), formatDateField(anatocismStartFrom)));
        }
    };
    useEffect(function () {
        dispatch(change("interestsForm", "interestsList[".concat(interestId, "].results"), calculateInterest(interestData, legalInterestRates)));
    }, [
        interestData.baseAmount,
        interestData.startDate,
        interestData.endDate,
        interestData.referenceRate,
        interestData.multiplyRate,
        interestData.multiplyBy,
        interestData.majoriseRate,
        interestData.majoriseBy,
        interestData.majoriseStartFrom,
        interestData.anatocism,
        interestData.anatocismStartFrom,
    ]);
    var displayActualRateCol = interestData.multiplyRate || interestData.majoriseRate;
    var displayResults = interestData.baseAmount &&
        interestData.baseAmount > 0 &&
        interestData.startDate &&
        interestData.endDate &&
        interestData.referenceRate && interestData.referenceRate.selected &&
        (interestData.referenceRate.selected !== 'custom' || (interestData.referenceRate.input && interestData.referenceRate.input > 0));
    return React.createElement("div", { className: 'mt-4' },
        React.createElement("div", { style: { display: "flex", gap: 16 } },
            React.createElement(Grid, { container: true, justify: "flex-start", spacing: 2, alignItems: "stretch", alignContent: "stretch" },
                React.createElement(Grid, { item: true },
                    React.createElement(Field, { name: "baseAmount", component: RenderTextField, label: "Cr\u00E9ance", type: "number", required: true, placeholder: "Saisissez le montant", InputProps: {
                            endAdornment: React.createElement(InputAdornment, { position: "end" }, "\u20AC"),
                            style: { textAlign: "right", maxWidth: 150 },
                        } })),
                React.createElement(Grid, { item: true },
                    React.createElement(Field, { name: "startDate", component: RenderTextField, label: "Date de d\u00E9but", type: "date", required: true, InputProps: {
                            style: { maxWidth: 150 },
                            inputProps: { max: interestData.endDate ? interestData.endDate : "" }
                        } })),
                React.createElement(Grid, { item: true },
                    React.createElement(Field, { name: "endDate", component: RenderTextField, label: "Date de fin", type: "date", required: true, InputProps: {
                            style: { maxWidth: 150 },
                            inputProps: { min: interestData.startDate ? interestData.startDate : "" }
                        } })),
                React.createElement(Grid, { item: true },
                    React.createElement(Field, { name: "referenceRate", component: renderInterestRateSelection, label: "Choix du taux", required: true })),
                React.createElement(Grid, { item: true, className: classes.commentField },
                    React.createElement(Field, { name: "comment", component: RenderTextField, label: "Commentaire", type: "text", className: "w-100" })))),
        React.createElement("div", { style: { marginLeft: -15, display: "flex", gap: 8, marginTop: 10 } },
            React.createElement(Field, { name: "multiplyRate", component: RenderSwitchField, label: "Multiplier le taux d'int\u00E9r\u00EAt" }),
            interestData.multiplyRate &&
                React.createElement(Field, { name: "multiplyBy", component: RenderTextField, label: "par", type: "number", placeholder: "x", InputProps: {
                        style: { textAlign: "right", maxWidth: "60px", minWidth: "50px", marginRight: 15 },
                    } }),
            React.createElement(Field, { name: "majoriseRate", component: RenderSwitchField, onChange: function (event, newValue, previousValue, name) { return handleMajoriseChange(newValue); }, label: "Majorer le taux d'int\u00E9r\u00EAt" }),
            interestData.majoriseRate &&
                React.createElement(React.Fragment, null,
                    React.createElement(Field, { name: "majoriseBy", component: RenderTextField, label: "de", type: "number", placeholder: "x", InputProps: {
                            endAdornment: React.createElement(InputAdornment, { position: "end" }, "pt(s)"),
                            style: { textAlign: "right", maxWidth: "95px", minWidth: "85px" },
                        } }),
                    React.createElement(Field, { name: "majoriseStartFrom", component: RenderTextField, label: "\u00E0 compter du", type: "date", InputProps: {
                            style: { marginRight: 15, maxWidth: 150, minWidth: 140 },
                            inputProps: { min: interestData.startDate ? interestData.startDate : "" }
                        } })),
            React.createElement(Field, { name: "anatocism", component: RenderSwitchField, onChange: function (event, newValue, previousValue, name) { return handleAnatocismChange(newValue); }, label: "Anatocisme", helpText: "anatocism" }),
            interestData.anatocism &&
                React.createElement(Field, { name: "anatocismStartFrom", component: RenderTextField, label: "\u00E0 compter du", type: "date", InputProps: {
                        style: { maxWidth: 150, minWidth: 140 },
                        inputProps: { min: interestData.startDate ? interestData.startDate : "" }
                    } })),
        displayResults ?
            React.createElement(React.Fragment, null,
                React.createElement(Paper, { className: classes.root, style: { minWidth: 1030, marginTop: 10, borderBottom: '1px solid #A7C2DD', }, elevation: 0 },
                    React.createElement(TableContainer, { className: classes.container },
                        React.createElement(Table, { size: "small", className: classes.table, stickyHeader: true, "aria-label": "customized table" },
                            React.createElement(TableHead, null,
                                React.createElement(TableRow, null,
                                    React.createElement(StyledTableCell, { align: "center", style: { width: 210 } }, "P\u00E9riode"),
                                    React.createElement(StyledTableCell, { align: "center", style: { width: 115 } }, "Nb de jours"),
                                    React.createElement(StyledTableCell, { align: "center", style: { width: 180 } },
                                        "Cr\u00E9ance",
                                        interestData.anatocism ? " avec intérêts échus (anatocisme)" : ""),
                                    React.createElement(StyledTableCell, { align: "center", style: { width: 115 } }, "Tx d'int\u00E9r\u00EAt de base"),
                                    displayActualRateCol && React.createElement(StyledTableCell, { align: "center", style: { width: 115 } }, "Tx d'int\u00E9r\u00EAt appliqu\u00E9"),
                                    React.createElement(StyledTableCell, { align: "center", style: { width: 150 } }, "Int\u00E9r\u00EAts calcul\u00E9s sur la p\u00E9riode"),
                                    React.createElement(StyledTableCell, { align: "center", style: { width: 150 } },
                                        "Int\u00E9r\u00EAts",
                                        interestData.anatocism ? " échus" : "",
                                        " cumul\u00E9s"),
                                    React.createElement(StyledTableCell, { align: "left" }, "Commentaires sur la p\u00E9riode"))),
                            React.createElement(TableBody, null, interestData.results && interestData.results.periods && interestData.results.periods.map(function (row, i) { return (React.createElement(StyledTableRow, { key: i },
                                React.createElement(StyledTableCell, { align: "center" },
                                    new Date(row.startDate).toLocaleDateString('fr-FR'),
                                    " - ",
                                    new Date(row.endDate).toLocaleDateString('fr-FR')),
                                React.createElement(StyledTableCell, { align: "center" }, row.nbDays),
                                React.createElement(StyledTableCell, { align: "right" }, row.periodBaseAmount && Intl.NumberFormat("fr-FR", {
                                    style: "currency",
                                    currency: "EUR"
                                }).format(row.periodBaseAmount)),
                                React.createElement(StyledTableCell, { align: "center" }, row.baseInterestRate && Intl.NumberFormat("fr-FR", {
                                    style: "percent",
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                }).format(row.baseInterestRate / 100)),
                                displayActualRateCol && React.createElement(StyledTableCell, { align: "center" }, row.interestRate && Intl.NumberFormat("fr-FR", {
                                    style: "percent",
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                }).format(row.interestRate / 100)),
                                React.createElement(StyledTableCell, { align: "right" }, row.interestAmount && Intl.NumberFormat("fr-FR", {
                                    style: "currency",
                                    currency: "EUR"
                                }).format(row.interestAmount)),
                                React.createElement(StyledTableCell, { align: "right" }, row.cumulativeInterestAmount && Intl.NumberFormat("fr-FR", {
                                    style: "currency",
                                    currency: "EUR"
                                }).format(row.cumulativeInterestAmount)),
                                React.createElement(StyledTableCell, { align: "left" }, row.comment))); }))))),
                ((_a = interestData.results) === null || _a === void 0 ? void 0 : _a.summary) &&
                    React.createElement(Grid, { container: true, justify: "center", spacing: 2, alignItems: "center" },
                        React.createElement(Grid, { item: true, className: "".concat(classes.centerContainer, " ").concat(classes.tableContainerGrid) },
                            React.createElement(ResultsTableWithRoundedCorners, null,
                                React.createElement(ResultsTable, { size: "small", className: classes.resultsTable, "aria-label": "customized table" },
                                    React.createElement(TableBody, null,
                                        React.createElement(ResultsTableRow, { className: classes.boldTableRow },
                                            React.createElement(ResultsTableCell, { className: classes.ResultsTableCell, component: "th", scope: "row", style: { width: 160 } }, "Total int\u00E9r\u00EAts"),
                                            React.createElement(ResultsTableCell, { align: "right" }, interestData.results.summary.interestsTotal && Intl.NumberFormat("fr-FR", {
                                                style: "currency",
                                                currency: "EUR"
                                            }).format(interestData.results.summary.interestsTotal))),
                                        React.createElement(ResultsTableRow, null,
                                            React.createElement(ResultsTableCell, { className: classes.ResultsTableCell, component: "th", scope: "row", style: { width: 160 } }, "Cr\u00E9ance"),
                                            React.createElement(ResultsTableCell, { align: "right" }, interestData.results.summary.baseAmount && Intl.NumberFormat("fr-FR", {
                                                style: "currency",
                                                currency: "EUR"
                                            }).format(interestData.results.summary.baseAmount))),
                                        React.createElement(ResultsTableRow, { className: "".concat(classes.boldTableRow, " ").concat(classes.resultsTableTotalRow) },
                                            React.createElement(ResultsTableCell, { component: "th", scope: "row", style: { width: 160 } }, "Total"),
                                            React.createElement(ResultsTableCell, { align: "right" }, interestData.results.summary.total && Intl.NumberFormat("fr-FR", {
                                                style: "currency",
                                                currency: "EUR"
                                            }).format(interestData.results.summary.total))))))))) : React.createElement(React.Fragment, null));
}
var mapStateToProps = function (state) {
    var newInterestCalc = { title: "", baseAmount: 0 };
    var initialValues = { activeTab: 0, interestsList: [newInterestCalc] };
    if (state.cases.case && state.cases.case && state.cases.case.interests_data)
        initialValues = {
            activeTab: state.cases.case.interests_data.activeTab,
            interestsList: state.cases.case.interests_data.interestsList || [newInterestCalc]
        };
    return { initialValues: initialValues };
};
var validate = function (values) {
    var _a, _b, _c;
    var _d, _e;
    var errors = {};
    var interestsList = values.interestsList, activeTab = values.activeTab;
    if (interestsList && interestsList[activeTab]) {
        var currentInterest = interestsList[activeTab];
        if (currentInterest.startDate && currentInterest.endDate) {
            var startDate = new Date(currentInterest.startDate);
            var endDate = new Date(currentInterest.endDate);
            if (startDate > endDate) {
                errors.interestsList = __assign(__assign({}, (errors.interestsList || {})), (_a = {}, _a[activeTab] = {
                    startDate: 'date de début > date de fin'
                }, _a));
            }
        }
        if (currentInterest.startDate && currentInterest.majoriseBy && currentInterest.majoriseStartFrom) {
            var startDate = new Date(currentInterest.startDate);
            var majoriseDate = new Date(currentInterest.majoriseStartFrom);
            if (startDate > majoriseDate) {
                errors.interestsList = __assign(__assign({}, (errors.interestsList || {})), (_b = {}, _b[activeTab] = __assign(__assign({}, (((_d = errors.interestsList) === null || _d === void 0 ? void 0 : _d[activeTab]) || {})), { majoriseStartFrom: 'date de majoration < date de début' }), _b));
            }
        }
        if (currentInterest.startDate && currentInterest.anatocism && currentInterest.anatocismStartFrom) {
            var startDate = new Date(currentInterest.startDate);
            var anatocismDate = new Date(currentInterest.anatocismStartFrom);
            if (startDate > anatocismDate) {
                errors.interestsList = __assign(__assign({}, (errors.interestsList || {})), (_c = {}, _c[activeTab] = __assign(__assign({}, (((_e = errors.interestsList) === null || _e === void 0 ? void 0 : _e[activeTab]) || {})), { anatocismStartFrom: "date d'anatocisme < date de début" }), _c));
            }
        }
    }
    return errors;
};
CaseInterestsButton = reduxForm({
    form: "interestsForm",
    enableReinitialize: true,
    validate: validate
})(CaseInterestsButton);
export default connect(mapStateToProps)(CaseInterestsButton);
