import React, {Component, Fragment, memo} from "react";
import PropTypes from "prop-types";
import {connect, Provider} from "react-redux";
import store from "../../../../store";
import {formValueSelector, getFormValues} from "redux-form";
import CaseVictimAccident from "./CaseVictimAccident"
import CaseMetadata from "./CaseMetadata";
import CaseData from "../case_data_form/CaseData";
import CaseFooter from "./CaseFooter";
import {deleteCase, getCase, updateCase} from "../../../../actions/cases";
import SideMenu from "../../../layout/SideMenu";
import SmallScreens from "./SmallScreens";
import DisplayWarningMsg from "./DisplayWarningMsg";
import CaseTitle from "./CaseTitle";
import {LoadingComponent} from "../../../common/LoadingComponent";
import {getTeamsAndMembers} from "../../../../actions/teams";
import CaseInterestsButton from "../../interests/CaseInterestsButton";

export class Case extends Component {
    static propTypes = {
        getCase: PropTypes.func.isRequired,
        deleteCase: PropTypes.func.isRequired,
        case: PropTypes.object,
        victim: PropTypes.object,
        getTeamsAndMembers: PropTypes.func.isRequired,
        teams: PropTypes.array.isRequired,
        caseValues: PropTypes.object,
        auth: PropTypes.object
    };


    state = {
        saveCounter: [],
        saveCounterTitle: [],
        isSaving: false,
        showProvisionDialog: false,
        showTicketDialog: false,
        showInterestsDialog: false,
    }


    processNextSave = () => {
        const {saveCounter, saveCounterTitle} = this.state;

        if (saveCounter.length === 0 && saveCounterTitle.length === 0) {
            return
        }

        this.setState({isSaving: true}, () => {
            if (saveCounterTitle.length > 0) {
                this.caseTitleSubmit(saveCounterTitle[0]).finally(() => {
                    this.setState(prevState => ({
                        isSaving: false,
                        saveCounterTitle: prevState.saveCounterTitle.slice(1)
                    }), this.processNextSave)
                })
                return
            }
            if (saveCounter.length > 0) {
                this.caseDataSubmit(saveCounter[0]).finally(() => {
                    this.setState(prevState => ({
                        isSaving: false,
                        saveCounter: prevState.saveCounter.slice(1)
                    }), this.processNextSave)
                })
                return
            }
        })
    };

    enqueueSave = (values) => {
        try {
            this.setState(prevState => ({
                saveCounter: [...prevState.saveCounter, values]
            }), () => {
                if (!this.state.isSaving) {
                    this.processNextSave();
                }
            })
        } catch (e) {
            console.error(e)
        }
    }

    componentDidMount() {
        this.props.getTeamsAndMembers();
        this.props.getCase(this.props.match.params.caseId);
    }

    caseDataSubmit = values => {
        const caseData = values;

        let c = {
            id: this.props.case.id,
            title: this.props.caseTitle,
            version: this.props.case.version,
            interests_data: {...this.props.case.interests_data, ...this.props.interests},
            json_data: {
                ...this.props.case.json_data,
                caseData,
                victim: {...this.props.case.json_data.victim, ...this.props.victimData},
            }
        };

        return this.props.updateCase(c);
    };


    caseTitleSubmit = (title) => {
        let c = {
            id: this.props.case.id,
            version: this.props.case.version,
            title: title,
        };

        return this.props.updateCase(c);
    };

    enqueueSaveTitle = () => {
        try {
            this.setState(prevState => ({
                saveCounterTitle: [...prevState.saveCounterTitle, this.props.caseTitle]
            }), () => {
                if (!this.state.isSaving) {
                    this.processNextSave();
                }
            })
        } catch (e) {
            console.error(e)
        }
    }

    render() {
        const {date_created, date_modified} = this.props.case || {};
        const dateCascadeReverse = new Date("2023-01-20")
        const dateCreated = new Date(date_created)


        let isTeamMember = false

        const users = {}
        if (this.props.teams && this.props.teams.length > 0) {
            isTeamMember = true
            this.props.teams.forEach(team => {
                team.members.forEach(member => {
                    users[member.user_id] = {email: member.user_email, name: member.user_name};
                });
            });
        }


        if (this.props.auth && this.props.auth.user) {
            users[this.props.auth.user.id] = {email: this.props.auth.user.email, name: this.props.auth.user.name};
        }

        const getNameByUserId = userId => users[userId] ? users[userId].name || users[userId].email || "Utilisateur inconnu" : "Utilisateur inconnu";

        return (
            <>
                {dateCreated < dateCascadeReverse && <DisplayWarningMsg/>}
                <div className="mobile"><SmallScreens/></div>
                <div className="desktop">
                    {this.props.isLoading && "Chargement du dossier en cours..."}

                    <Provider store={store}>
                        {Object.keys(this.props.case).length > 0 ?
                            <>
                                <div className="wrapper">
                                    <SideMenu victim={this.props.victim}/>
                                    <div className="container-fluid">
                                        <div className="sticky-top sticky-offset">
                                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                <div>
                                                    <CaseTitle onSubmit={this.enqueueSaveTitle} title={this.props.case.title}/>
                                                    <CaseMetadata date_created={date_created}
                                                                date_modified={date_modified}
                                                                created_by={getNameByUserId(this.props.case.created_by_id)}
                                                                modified_by={getNameByUserId(this.props.case.modified_by_id)}
                                                                is_team_member={isTeamMember}
                                                    />
                                                </div>
                                                <div style={{alignContent: "center", columnGap: 10}}>
                                                    {this.props.auth && this.props.auth.user && this.props.auth.user.beta_tester_interests && <CaseInterestsButton interests={this.props.interests}/>}
                                                </div>
                                            </div>
                                            <CaseVictimAccident/>
                                        </div>
                                        <CaseData onSubmit={this.enqueueSave} victim={this.props.victim}/>
                                    </div>
                                </div>
                                <CaseFooter caseId={this.props.case.id}/>
                            </>
                            : <LoadingComponent/>
                        }
                    </Provider>
                </div>
            </>

        );
    }
}

const victimSelector = formValueSelector("victim");
const titleSelector = formValueSelector("caseTitleForm");
const dataSelector = formValueSelector("caseDataForm");

const mapStateToProps = state => {
    let victim = {};
    let victimData = {};
    let caseTitle = "";
    let interests = { activeTab: 0, interestsList: [{}]};


    let c = {};
    let caseValues = {};
    let indirectVictims = [];

    if (state.cases && state.cases.case) {
        c = state.cases.case;
        if (state.cases.case && state.cases.case.title) {
            caseTitle = titleSelector(state, "title")
        }
        if (state.cases && state.cases.case && state.cases.case.json_data) {
            victimData.last_name = victimSelector(state, "last_name");
            victimData.first_name = victimSelector(state, "first_name");

            victimData.rateOfCompensationEntitlement = victimSelector(
                state,
                "rateOfCompensationEntitlement"
            );
            victimData.disableCascade = victimSelector(state, "disableCascade");
            victimData.consolidationDate = victimSelector(state, "consolidationDate");
            victimData.deathDate = victimSelector(state, "deathDate");
            victimData.date = victimSelector(state, "date");
            victimData.accidentType = victimSelector(state, "accidentType");
            victimData.birthDate = victimSelector(state, "birth_date");
            victimData.gender = victimData.victimGender = victimSelector(state, "victimGender"); // to compensate for bad choice of variable names in direct victim. To be fixed!!!
            victim = victimData;

            caseValues = getFormValues("caseDataForm")(state);
            if (caseValues) indirectVictims = caseValues.indirectVictims;

        }

        if (state.cases.case.interests_data) 
            interests = state.cases.case.interests_data;
        let interestsValues = getFormValues("interestsForm")(state);
        if (interestsValues) {
            interests = interestsValues;
        }
    }

    return {
        caseValues,
        victim,
        victimData,
        caseTitle,
        interests,
        teams: state.teams.teams,
        case: c, indirectVictims,
        auth: state.auth
    };
};

export default connect(mapStateToProps, {getCase, deleteCase, updateCase, getTeamsAndMembers})(memo(Case));
