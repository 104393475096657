import React from "react";
import { Link as LinkIcon, LinkOff as LinkOffIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  icon: {
    cursor: 'pointer',
    transition: '0.3s',
  },
  disabledIcon: {
    cursor: 'not-allowed',
    opacity: 0.5,
    color: theme.palette.action.disabled,
    pointerEvents: 'none',
  },
}));

export const BulkEdit = props => {
  const { editAll, setEditAll, enableBulkEdit, handleClickOpen, daysPerYear } = props;
  const classes = useStyles();
  const handleClick = () => {

    if (!editAll)
      handleClickOpen()
    else
      setEditAll(false)
  }
  const Icon = editAll ? LinkIcon : LinkOffIcon;
  const ToolTipText = <><strong>Données liées ou déliées ?</strong><br />En activant cette icône <LinkIcon fontSize="small" style={{fontSize: "15px"}} />, vous choisissez de faire correspondre automatiquement les données ci-dessous sur toutes les périodes.<br />En désactivant cette icône <LinkOffIcon style={{fontSize: "15px"}} />, les données ci-dessous sont autonomes les unes des autres et peuvent ainsi être différentes d'une période à l'autre.</>

  return (
    <React.Fragment>
      <Tooltip title={ToolTipText} leaveDelay={200} placement="top">
        <Icon
          onClick={handleClick}
          fontSize="small"
          variant="contained"
          className={`text-primary ${classes.icon} ${!enableBulkEdit ? classes.disabledIcon : ''}${daysPerYear ? " daysPerYear" : ""}`}
          style={{ float: 'right' }}
        />
      </Tooltip>
    </React.Fragment >
  );
};