import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { itemTypes } from "../itemTypes";
import HelpTip from "../../../layout/HelpTip";
import { BulkEdit } from "../../../layout/BulkEdit";
import { ConfirmBulkEditModal } from "./ConfirmBulkEditModal";
import { checkAllValSame } from "./utils";
import { change } from "redux-form";
import { useDispatch } from "react-redux";

export function ItemTableHeader(props) {
  const { entry, indrectEntry, victimId, indirectVictim, enableDFT0BulkEdit, enableTPT0BulkEdit, enableTPF0BulkEdit, item, editAllDFT0, setEditAllDFT0, editAllTPT0periodRate, setEditAllTPT0periodRate, editAllTPT0daysPerYear, setEditAllTPT0daysPerYear, editAllTPF0periodRate, setEditAllTPF0periodRate, editAllTPF0daysPerYear, setEditAllTPF0daysPerYear, editAllPIndperiodRate, setEditAllPIndperiodRate, editAllPIndDaysPerYear, setEditAllPIndDaysPerYear, DFT0, TPT0, TPF0 } = props;
  const [DFTOpen, setDFTOpen] = React.useState(false);
  const [TPTOpen, setTPT0periodRateOpen] = React.useState(false);
  const [TPTHourOpen, setTPT0daysPerYearOpen] = React.useState(false);
  const [TPF0periodRateOpen, setTPF0periodRateOpen] = React.useState(false);
  const [TPF0daysPerYearOpen, setTPF0daysPerYearOpen] = React.useState(false);
  const [PIndperiodRateOpen, setPIndperiodRateOpen] = React.useState(false);
  const [PInddaysPerYearOpen, setPInddaysPerYearOpen] = React.useState(false);
  const dispatch = useDispatch();

  const handleClickOpen = (itemTypeData, itemType, name, setOpenFunc, setEditAllFunc, confirmBulkEditFunc) => {
    const isSame = checkAllValSame(itemTypeData, name)
    if (!isSame)
      setOpenFunc(true);
    else {
      setEditAllFunc(true)
      confirmBulkEditFunc(itemTypeData, itemType, name);
    }
  }

  const handleIndirectVictimsClickOpen = (indirectVictim, name, setOpenFunc, setEditAllFunc, confirmBulkEditFunc, victimId) => {
    const isSame = checkAllValSame(indirectVictim, name)
    if (!isSame)
      setOpenFunc(true);
    else {
      setEditAllFunc(true)
      confirmBulkEdit(indirectVictim, `indirectVictims[${victimId}].caseData.PInd0`, 'periodRate')
    }
  };

  const handleClose = (setOpenFunc) => {
    setOpenFunc(false)
  }

  const handleConfirm = (itemTypeData, itemType, name, setOpenFunc, setEditAllFunc, confirmBulkEditFunc) => {
    setEditAllFunc(true)
    confirmBulkEditFunc(itemTypeData, itemType, name);
    setOpenFunc(false);
  }

  const handleIndirectVictimsConfirm = (name, setOpenFunc, setEditAllFunc, confirmBulkEditFunc, victimId) => {
    setEditAllFunc(true)
    confirmBulkEditFunc(indirectVictim, `indirectVictims[${victimId}].caseData.PInd0`, name)
    setOpenFunc(false)
  }

  const fieldInitialVal = {
    'daysPerYear': 0,
    'periodRate': ''
  }
  const confirmBulkEdit = (data, dataType, field) => {
    if (!data[0][field]) {
      dispatch(change("caseDataForm", `${dataType}[0][${field}]`, fieldInitialVal[field]));
      data[0][field] = '';
    }
    const firstLine = data[0][field];
    for (let i = 1; i < data.length; i++) {
      if (data[i][field] !== firstLine) {
        dispatch(change("caseDataForm", `${dataType}[${i}][${field}]`, firstLine));
      }
    }
  };

  return (
    <Fragment>
      <thead>
        <tr>
          <th scope="col" className="col-num" />
          {itemTypes.get(item.itemType).length === 0 ? (
            <td />
          ) : (
            itemTypes.get(item.itemType).map((field, k) => (
              <th key={k} scope="col" className={field.fieldType} style={item.itemType === "DFT" && field.name === "periodRate" ? {minWidth: "145px"}:{}}>
                {field.name === "label" && item.labelField ? item.labelField : field.label}
                {field.fieldType === "duration" && <HelpTip helpText="durationHeader" />}
                {field.name === "workIncome" && <HelpTip helpText="workIncomeHeader" />}
                {field.fieldType === "refAmount" && <HelpTip helpText="referenceIncomeHeader" />}
                {field.fieldType === "datePeriod" && <HelpTip helpText="datePeriodHeader" />}
                {field.name === "hours" && <HelpTip helpText="hours" />}
                {item.itemType === "TPT" && field.name === "startDate" && <HelpTip helpText="tptDateHeader" />}
                {entry && entry.id === "TPT" && field.name === "periodRate" && <BulkEdit editAll={editAllTPT0periodRate} setEditAll={setEditAllTPT0periodRate} enableBulkEdit={enableTPT0BulkEdit} handleClickOpen={() => handleClickOpen(TPT0, 'TPT0', 'periodRate', setTPT0periodRateOpen, setEditAllTPT0periodRate, confirmBulkEdit)} />}
                {entry && entry.id === "TPT" && field.name === "hours" && <BulkEdit editAll={editAllTPT0daysPerYear} setEditAll={setEditAllTPT0daysPerYear} enableBulkEdit={enableTPT0BulkEdit} handleClickOpen={() => handleClickOpen(TPT0, 'TPT0', 'daysPerYear', setTPT0daysPerYearOpen, setEditAllTPT0daysPerYear, confirmBulkEdit)} daysPerYear={true} />}

                {entry && entry.id === "TPF" && item.label === "Arrérages échus" && field.name === "periodRate" && <BulkEdit editAll={editAllTPF0periodRate} setEditAll={setEditAllTPF0periodRate} enableBulkEdit={enableTPF0BulkEdit} handleClickOpen={() => handleClickOpen(TPF0, 'TPF0', 'periodRate', setTPF0periodRateOpen, setEditAllTPF0periodRate, confirmBulkEdit)} />}
                {entry && entry.id === "TPF" && item.label === "Arrérages échus" && field.name === "hours" && <BulkEdit editAll={editAllTPF0daysPerYear} setEditAll={setEditAllTPF0daysPerYear} enableBulkEdit={enableTPF0BulkEdit} handleClickOpen={() => handleClickOpen(TPF0, 'TPF0', 'daysPerYear', setTPF0daysPerYearOpen, setEditAllTPF0daysPerYear, confirmBulkEdit)} daysPerYear={true} />}
                {entry && entry.id === "TPF" && <ConfirmBulkEditModal open={TPF0daysPerYearOpen} field_label="nombre de jours par an" first_line_field_value={(TPF0 && TPF0[0]?.daysPerYear+ " jours") ?? 0} onClose={() => handleClose(setTPF0daysPerYearOpen)} handleConfirm={() => handleConfirm(TPF0, 'TPF0', 'daysPerYear', setTPF0daysPerYearOpen, setEditAllTPF0daysPerYear, confirmBulkEdit)} />}
                {entry && entry.id === "TPF" && <ConfirmBulkEditModal open={TPF0periodRateOpen} field_label="taux horaire" first_line_field_value={(TPF0 && TPF0[0]?.periodRate+ " €") ?? ''} onClose={() => handleClose(setTPF0periodRateOpen)} handleConfirm={() => handleConfirm(TPF0, 'TPF0', 'periodRate', setTPF0periodRateOpen, setEditAllTPF0periodRate, confirmBulkEdit)} />}

                {indrectEntry && indrectEntry.id === "PInd" && item.label === "Arrérages échus" && field.name === "periodRate" && <BulkEdit editAll={editAllPIndperiodRate} setEditAll={setEditAllPIndperiodRate} enableBulkEdit={indirectVictim && indirectVictim.length > 1} handleClickOpen={() => handleIndirectVictimsClickOpen(indirectVictim, 'periodRate', setPIndperiodRateOpen, setEditAllPIndperiodRate, confirmBulkEdit, victimId)} />}
                {indrectEntry && indrectEntry.id === "PInd" && item.label === "Arrérages échus" && field.name === "hours" && <BulkEdit editAll={editAllPIndDaysPerYear} setEditAll={setEditAllPIndDaysPerYear} enableBulkEdit={indirectVictim && indirectVictim.length > 1} handleClickOpen={() => handleIndirectVictimsClickOpen(indirectVictim, 'daysPerYear', setPInddaysPerYearOpen, setEditAllPIndDaysPerYear, confirmBulkEdit, victimId)} daysPerYear={true} />}
                {indrectEntry && indrectEntry.id === "PInd" && <ConfirmBulkEditModal open={PInddaysPerYearOpen} field_label="nombre de jours par an" first_line_field_value={(indirectVictim && indirectVictim[0]?.daysPerYear+ " jours") ?? 0} onClose={() => handleClose(setPInddaysPerYearOpen)} handleConfirm={() => handleIndirectVictimsConfirm('daysPerYear', setPInddaysPerYearOpen, setEditAllPIndDaysPerYear, confirmBulkEdit, victimId)} />}
                {indrectEntry && indrectEntry.id === "PInd" && <ConfirmBulkEditModal open={PIndperiodRateOpen} field_label="taux horaire" first_line_field_value={(indirectVictim && indirectVictim[0]?.periodRate+ " €") ?? ''} onClose={() => handleClose(setPIndperiodRateOpen)} handleConfirm={() => handleIndirectVictimsConfirm('periodRate', setPIndperiodRateOpen, setEditAllPIndperiodRate, confirmBulkEdit, victimId)} />}

                {item.itemType === "TPT" && TPT0 && <ConfirmBulkEditModal open={TPTHourOpen} field_label="nombre de jours par an" first_line_field_value={(TPT0 && TPT0[0]?.daysPerYear+ " jours") ?? 0} onClose={() => handleClose(setTPT0daysPerYearOpen)} handleConfirm={() => handleConfirm(TPT0, 'TPT0', 'daysPerYear', setTPT0daysPerYearOpen, setEditAllTPT0daysPerYear, confirmBulkEdit)} />}
                {item.itemType === "TPT" && TPT0 && <ConfirmBulkEditModal open={TPTOpen} field_label="taux horaire" first_line_field_value={(TPT0 && TPT0[0]?.periodRate+ " €") ?? ''} onClose={() => handleClose(setTPT0periodRateOpen)} handleConfirm={() => handleConfirm(TPT0, 'TPT0', 'periodRate', setTPT0periodRateOpen, setEditAllTPT0periodRate, confirmBulkEdit)} />}

                {item.itemType === "DFT" && field.name === "startDate" && <HelpTip helpText="dftDateHeader" />}
                {item.itemType === "DFT" && field.name === "label" && <HelpTip helpText="dftLabelHeader" />}
                {item.itemType === "DFT" && field.name === "periodRate" && <BulkEdit editAll={editAllDFT0} setEditAll={setEditAllDFT0} enableBulkEdit={enableDFT0BulkEdit} handleClickOpen={() => handleClickOpen(DFT0, 'DFT0', 'periodRate', setDFTOpen, setEditAllDFT0, confirmBulkEdit)} />}
                {item.itemType === "DFT" && <ConfirmBulkEditModal open={DFTOpen} field_label="montant journalier" first_line_field_value={(DFT0 && DFT0[0]?.periodRate+ " €") ?? ''} onClose={() => handleClose(setDFTOpen)} handleConfirm={() => handleConfirm(DFT0, 'DFT0', 'periodRate', setDFTOpen, setEditAllDFT0, confirmBulkEdit)} />}
                {item.itemType === "PGPA" && field.name === "label" && <HelpTip helpText="pgpaLabelHeader" />}
                {item.itemType === "IP" && field.name === "label" && <HelpTip helpText="component" />}
              </th>
            ))
          )}

          <th scope="col" className="col-amount text-center" colSpan="2">
            {item.amountLabel ? item.amountLabel : `Préjudice${item.annuityCapitalisation ? " annuel" : ""}`}
          </th>
        </tr>
      </thead>
    </Fragment>
  );
}

ItemTableHeader.propTypes = {
  item: PropTypes.object.isRequired
};