import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { videoDemos } from "../constants";
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: 'none',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },

}));

export default function VideoDemo(props) {
    const { entryId, entryTitle } = props;
    const videoURL = videoDemos[entryId] + "?autoplay=1"
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className="ml-2" onClick={((e) => e.stopPropagation())}>
            <button type="button" onClick={handleOpen} className="btn btn-outline-primary btn-sm smallIcon" title="Visualiser la vidéo de présentation du poste">
                <OndemandVideoIcon fontSize="small" />
            </button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <h2 id="transition-modal-title">{entryTitle}</h2>
                        <p id="transition-modal-description"><iframe width="560" height="315" style={{ border: 0 }} src={videoURL} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></p>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}